import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


async function checkHealth() {
  try {
    var license = await store.dispatch("checkHealth");
    console.log(license)
    if (license.status != 200) {
    
      router.push('/blackhole')
    } else {
      //
    }
  } catch (error) {
    console.log(error);
    router.push('/blackhole')

  }
}

// checkHealth()