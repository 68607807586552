<template>
  <div>
    <v-app v-if="$store.state.config.frame_less.includes($route.path)">
     <router-view></router-view>
    </v-app>
    <v-app v-else>
      <router-view></router-view>
    </v-app>
  </div>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>