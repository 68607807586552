import Vue from 'vue'
import VueRouter from 'vue-router'
 
Vue.use(VueRouter)
function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: lazyLoad('HomeView')
  },
  {
    path: '/login',
    name: 'login',
    component: lazyLoad('SignInView')
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
