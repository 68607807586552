import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
Vue.use(Vuex);
const HEALTH_CHECK = "http://localhost:3001/whatsapp/meta";
export default new Vuex.Store({
  state: {
    config: {
      frame_less: ["/login"],
      serverOnline: {},
    },
  },
  getters: {
    server_status(state) {
      return state.config.serverOnline;
    },
  },
  mutations: {
    setConfig(state,payload)
    {
      state.config.serverOnline = payload
    }
  },
  actions: {
    checkHealth({commit}) {
      return new Promise((resolve, reject) => {
        axios
          .get(HEALTH_CHECK)
          .then((response) => {
            if (response.status === 200)
            {
              commit('setConfig', response.data)
              return (response.status)
            } else 
            {
              commit('setConfig', {})
              return (response.status)

            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  modules: {},
});
